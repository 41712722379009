import React, { useEffect, useState } from 'react';
import { ReactSortable } from "react-sortablejs";
import Alert from 'react-bootstrap/Alert';
import axiosInstance from '../../utility/axios';

const EditCarousel = ({ lang }) => {
  const [images, setImages] = useState([]);
  const [previewImage, setPreviewImage] = useState([]);
  const [responseMsg, setResponseMsg] = useState('');

    const refreshBannerImages = () => {
        axiosInstance
        .get(`/site/getBannerImages/${lang}`)
        .then((response) => {
          if (response.status === 200) {
            setImages([...response.data?.response]);
          } else {
            // TODO: alert
            setResponseMsg('Some Error Occured');
            setImages([]);
          }
        })
        .catch((err) => {
            setImages([]);
            setResponseMsg(err);
        });
    }
    const handleImageAdd = (event) => {
        const confirmUpload = window.confirm("Are you sure you want to upload this file? \nIt will upload to the server and will be visible to the public as banner on https://surakshachakra.myambar.org/");    
        if (confirmUpload) {
          const formData = new FormData();
          formData.append('file', event.target.files[0]);
          formData.append('sortId', images.length+1);
          axiosInstance
          .post(`/site/addBannerImage/${lang}`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
          .then((response) => {
            if (response.status === 200) {
              refreshBannerImages()
              setResponseMsg('New Image Added Successfully');
            } else {
              // TODO: alert
              setResponseMsg('Some Error Occured');
            }
          })
          .catch((err) => {
              setResponseMsg(err);
          });
        }
        
      };

      useEffect(() => {
        refreshBannerImages()
        return () => {
          setImages([]);
        };
      }, [])

  const handleChoose = (event) => {
    setPreviewImage(event.target.src);
  };

      const saveBannerImages = () => {
        const formData = new FormData();
        formData.append('data',JSON.stringify(images));
        axiosInstance
        .patch(`/site/updateBannerImages/${lang}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
        .then((response) => {
          if (response.status === 200) {
            refreshBannerImages()
            setResponseMsg('Order Updated Successfully');
          } else {
            // TODO: alert
            setResponseMsg('Some Error Occured');
          }
        })
        .catch((err) => {
            setResponseMsg(err);
        });
      }

  const handleDrag = (e) => {
    e.dataTransfer.setData('id', e.target.id);
  };

  const handleTrashDrop = (evt) => {
    const index = evt.dataTransfer.getData('id');
    const id = images[index].id;
    axiosInstance
      .delete(`/site/deleteBannerImage/${id}/${lang}`)
      .then((response) => {
        if (response.status === 200) {
          refreshBannerImages();
          setResponseMsg('Image Deleted Successfully');
        } else {
          setResponseMsg('Some Error Occured');
        }
      })
      .catch((err) => {
        setResponseMsg(err);
      });
  };

    return (
        <div id="edit-carousel">
          {
            responseMsg && <Alert variant="info" onClose={() => setResponseMsg('')} dismissible>
                              <p>
                                {responseMsg}
                              </p>
                            </Alert>
          }
            <div style={{display:"flex", justifyContent:"space-between", flexWrap:"wrap"}}>
                <input type="file" accept=".jpg,.jpeg,.png" onChange={handleImageAdd} />
                <div className="trash" onDragOver={(e) => e.preventDefault()} onDrop={handleTrashDrop}>
                    <i className='bx bxs-trash-alt bx-md'></i>
                </div>
                <button onClick={()=>saveBannerImages()} className="banner-btn">Save Order of Images</button>
            </div>
        
        <div className="banner-images-container">
            <ReactSortable list={images} setList={setImages} id="banner-images" 
                    animation={200}
                    delayOnTouchStart={true}
                    delay={2}
                    >
                {images.map((item, index) => (
                    <li key={index} draggable="true" onClick={handleChoose} id={index} onDragStart={handleDrag}>
                        <img src={`${process.env.REACT_APP_API}/banner/${lang}/${item.fileId}${item.fileExt}`} alt={`Image ${index}`} />
                    </li>
                ))}
            </ReactSortable>
            <div className="banner-images-container-preview">
                <img src={previewImage} alt="" />
            </div>
        
        </div>
        <Alert variant="info" style={{marginTop: "1em"}}>
          <p>
            Note: 
          </p>
            <ul>
              <li>Once you upload, Image will be saved automatically and is updated on live site. Be sure what you are uploading</li>
              <li>Drag image to change order and then Save the Order</li>
              <li>Drag Image to trash icon to delete it</li>
              <li>Click on image to view in full screen</li>
            </ul>
        </Alert>
        </div>
    )
}


export default EditCarousel;

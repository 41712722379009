import React, { useState } from 'react';
import axiosInstance from '../utility/axios';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Table from '../components/table/Table';
import Edit from '../components/blog/edit';
import Preview from '../components/blog/preview';
import ScaleLoader from 'react-spinners/ScaleLoader';
import Alert from 'react-bootstrap/Alert';
import { useEffect } from 'react';

let limit = 5;
const TableHead = [
  '',
  'Title',
  'Authors',
  // 'short description',
  '',
  '',
  '',
];

const renderHead = (item, index) => <th key={index}>{item}</th>;

const renderBody = (
  item,
  index,
  setEditBlog,
  setNavPreview,
  handleClick,
  currPage,
  renderTooltip
) => (
  <tr key={index}>
    <td>{currPage * limit + index + 1}</td>
    <td>{item.title}</td>
    <td>{item.authors}</td>
    {/* <td>{item.shortDesp}</td> */}
    <td>
      <OverlayTrigger
        placement="bottom"
        delay={{ show: 250, hide: 400 }}
        overlay={(props) => renderTooltip(props, 'Preview')}
      >
        <i
          className="bx bxs-show"
          style={{ cursor: 'pointer', fontSize: '30px' }}
          onClick={() => {
            setNavPreview(true);
            setEditBlog(item.id);
          }}
        ></i>
      </OverlayTrigger>
    </td>
    <td>
      <OverlayTrigger
        placement="bottom"
        delay={{ show: 250, hide: 400 }}
        overlay={(props) => renderTooltip(props, 'Edit')}
      >
        <i
          className="bx bxs-edit-alt"
          onClick={() => {
            setNavPreview(false);
            setEditBlog(item.id);
          }}
          style={{ cursor: 'pointer', fontSize: '30px' }}
        ></i>
      </OverlayTrigger>
    </td>
    <td>
      <OverlayTrigger
        placement="bottom"
        delay={{ show: 250, hide: 400 }}
        overlay={(props) => renderTooltip(props, 'Delete')}
      >
        <i
          className="bx bxs-trash"
          data-bs-toggle="modal"
          data-bs-target="#blog"
          style={{ cursor: 'pointer', fontSize: '30px' }}
          onClick={() => handleClick(item.id)}
        ></i>
      </OverlayTrigger>
    </td>
  </tr>
);

const Blogs = () => {
  const [blogList, setBlogList] = useState([]);
  const [tags, setTags] = useState([]);
  const [tagResponseMsg, setTagResponseMsg] = useState('');
  const [showTagFields, setShowTagFields] = useState(false);
  const [editBlog, setEditBlog] = useState('');
  const [navPreview, setNavPreview] = useState(true);
  const [responseMsg, setResponseMsg] = useState('');
  const [fileError, setFileError] = useState('');
  const [blogImageTracker, setBlogImageTracker] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [itemId, setItemId] = useState(-1);
  const [state, setState] = useState();
  const [loader, setLoader] = useState(false);

  const [form, setForm] = useState({
    id: '',
    title: '',
    authors: '',
    source: '',
    date: '',
    tag: [],
    shareLink: '',
    blogURL: '',
    demoImage: '',
    bannerImage: '',
    authorImage: '',
    bannerImageExt: '',
    bannerImageId: '',
    authorImageExt: '',
    authorImageId: '',
    demoImageExt: '',
    demoImageId: '',
    shortDesp: '',
    content: '',
  });
  useEffect(() => {
    blogImageTracker.forEach((b, ind) => {
      if (form.content.search(b) === -1) {
        setBlogImageTracker([...blogImageTracker.filter((f) => f !== b)]);
        axiosInstance
          .post(`/blogs/image/remove`, {
            file: b,
          })
          .then((response) => {})
          .catch((err) => {});
      }
    });
  }, [form.content]);
  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.match('image.*') && file.size <= 5000000) {
      setForm({ ...form, [e.target.name]: file });
      setFileError({ ...fileError, [e.target.name]: '' });
    } else {
      setFileError({
        ...fileError,
        [e.target.name]: 'File can have max size of 5MB',
      });
    }
  };

  const renderTooltip = (props, text) => (
    <Tooltip id="button-tooltip" {...props}>
      {text}
    </Tooltip>
  );

  const updateTags = () => {
    axiosInstance
      .get(`/blogs/getTags`, {
        headers: {
          isadmin: true,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setTags([...response.data?.response]);
          setShowTagFields(false);
        } else {
          setTagResponseMsg('Unable to get Tags');
        }
      })
      .catch((err) => {
        setTagResponseMsg(err);
      });
  };

  const createTag = (tagName) => {
    axiosInstance
      .post(`/blogs/createTags/`, {
        tagName,
      })
      .then((response) => {
        if (response.status === 200) {
          updateTags();
          setShowTagFields(false);
          setTagResponseMsg(response.data?.message);
        } else {
          setTagResponseMsg('Unable to create Tag');
        }
      })
      .catch((err) => {
        setTagResponseMsg(err);
      });
  };

  const removeTag = (id, setShowModal) => {
    axiosInstance
      .delete(`/blogs/deleteTags/${id}`)
      .then((response) => {
        if (response.status === 200) {
          updateTags();
          setShowTagFields(false);
          setTagResponseMsg(response.data?.message);
        } else {
          // TODO
          setTagResponseMsg('Unable to remove Tag');
        }
      })
      .catch((err) => {
        setResponseMsg(err);
      });
    setShowModal(false);
  };

  const removeSelectedFile = (name) => {
    const fileInput = document.getElementById(name);
    fileInput.value = '';
    // selectedFile = undefined;
    setForm({ ...form, [name]: '' });
    setFileError({ ...fileError, [name]: '' });
  };

  const handleContentChange = (event, editor) => {
    const data = editor.getData();
    setForm((prevForm) => ({ ...prevForm, content: data }));
  };

  const handleTagChange = (e) => {
    if (form.tag.some((f) => f === e.target.value)) {
      setForm({ ...form, tag: form.tag.filter((f) => f !== e.target.value) });
    } else {
      setForm({ ...form, tag: [...form.tag, e.target.value] });
    }
  };

  const handleSubmit = (e) => {
    setLoader(true);
    e.preventDefault();
    const formData = new FormData();
    formData.append('authorImage', form.authorImage);
    formData.append('authors', form.authors);
    formData.append('bannerImage', form.bannerImage);
    formData.append('content', form.content);
    formData.append('date', form.date);
    formData.append('demoImage', form.demoImage);
    formData.append('shortDesp', form.shortDesp);
    formData.append('source', form.source);
    formData.append('tag', form.tag?.join(', '));
    formData.append('title', form.title);
    if (form.id) {
      const blog = blogList.filter((b) => b.id === form.id)[0];
      formData.append('demoImageId', blog.demoImageId);
      formData.append('bannerImageId', blog.bannerImageId);
      formData.append('authorImageId', blog.authorImageId);
      axiosInstance
        .patch(`/blogs/update/${form.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setEditBlog('');
            updateTable();
            setBlogImageTracker([]);
            setLoader(false);
          } else {
            // TODO: alert
          }
          setResponseMsg(response.data?.message);
        })
        .catch((err) => {
          setResponseMsg(err);
        });
    } else {
      axiosInstance
        .post(`/blogs/create`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setEditBlog('');
            updateTable();
            setBlogImageTracker([]);
            setLoader(false);
          } else {
            // TODO: alert
          }
          setResponseMsg(response.data?.message);
        })
        .catch((err) => {
          setResponseMsg(err);
        });
    }
  };
  const handleClick = (id) => {
    setItemId(id);
    setShowModal(true);
  };
  const handleDelete = (e, id) => {
    e.stopPropagation();
    axiosInstance
      .delete(`/blogs/delete/${id}`)
      .then((response) => {
        if (response.status === 200) {
          updateTable();
          setBlogImageTracker([]);
        } else {
          // TODO: alert
        }
        setResponseMsg(response.data?.message);
      })
      .catch((err) => {
        setResponseMsg(err);
      });
    setShowModal(false);
  };

  useEffect(() => {
    if (editBlog === '' || editBlog === -1) {
      setForm({
        ...form,
        id: '',
        title: '',
        authors: '',
        source: '',
        date: '',
        tag: [],
        demoImage: '',
        bannerImage: '',
        authorImage: '',
        shortDesp: '',
        content: '',
        shareLink: '',
        blogURL: '',
        bannerImageExt: '',
        bannerImageId: '',
        authorImageExt: '',
        authorImageId: '',
        demoImageExt: '',
        demoImageId: '',
      });
    } else {
      const blog = blogList.filter((b) => b.id === editBlog)[0];
      setForm({
        ...form,
        id: blog?.id || '',
        title: blog?.title || '',
        authors: blog?.authors || '',
        source: blog?.source || '',
        date: blog?.date || '',
        tag: blog?.tag || [],
        demoImage:
          blog?.demoImage ||
          (blog.demoImageId &&
            `${process.env.REACT_APP_API}/blogs/demo/${blog.demoImageId}${blog.demoImageExt}`) ||
          '',
        bannerImage:
          blog?.bannerImage ||
          (blog.bannerImageId &&
            `${process.env.REACT_APP_API}/blogs/banner/${blog.bannerImageId}${blog.bannerImageExt}`) ||
          '',
        authorImage:
          blog?.authorImage ||
          (blog.authorImageId &&
            `${process.env.REACT_APP_API}/blogs/author/${blog.authorImageId}${blog.authorImageExt}`) ||
          '',
        shortDesp: blog?.shortDesp || '',
        content: blog?.content || '',
        shareLink: blog?.shareLink || '',
        blogURL: blog?.blogURL || '',
        bannerImageExt: blog?.bannerImageExt || '',
        bannerImageId: blog?.bannerImageId || '',
        authorImageExt: blog?.authorImageExt || '',
        authorImageId: blog?.authorImageId || '',
        demoImageExt: blog?.demoImageExt || '',
        demoImageId: blog?.demoImageId || '',
      });
    }
  }, [editBlog]);

  const updateTable = () => {
    axiosInstance
      .get(`/blogs/readAll`, {
        headers: {
          isadmin: true,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          let temp = [...response.data?.response];
          temp = temp.map((t) => {
            t.tag = t.tag?.split(', ');
            return t;
          });
          setBlogList([...temp]);
        } else {
          // TODO
        }
      })
      .catch((err) => {
        setResponseMsg(err);
      });
  };

  useEffect(() => {
    updateTable();
    updateTags();
    return () => {
      setState({});
    };
  }, []);

  return (
    <div id="blog-container">
      {
        responseMsg && <Alert variant="info" onClose={() => setResponseMsg('')} dismissible>
                          <p>
                            {responseMsg}
                          </p>
                        </Alert>
      }
      {!loader ? (
        <>
          <div
            className={`modal ${showModal ? 'show' : ''} fade`}
            tabIndex="-1"
            style={{ display: showModal ? 'block' : 'none' }}
            id="blog"
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-lg modal-dialog-centered"
              role="document"
            >
              <div className="modal-content">
                <div
                  className="modal-header"
                  style={{ backgroundColor: '#008b8b' }}
                >
                  <h5 className="modal-title" style={{ color: 'white' }}>
                    Delete
                  </h5>
                  <button
                    className="btn-close btn-close-white"
                    type="button"
                    aria-label="Close"
                    data-bs-dismiss="modal"
                    onClick={() => setShowModal(false)}
                  ></button>
                </div>
                <div className="modal-body">
                  <div style={{ marginLeft: '1rem' }}>
                    Are you sure want to delete this item?
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    className="btn btn-light"
                    type="button"
                    id="TableModalClose"
                    data-bs-dismiss="modal"
                    onClick={() => setShowModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    name="next"
                    onClick={(e) => handleDelete(e, itemId)}
                    data-bs-dismiss="modal"
                    className="btn btn-primary action-button"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="page-header">
            <h2>Blogs</h2>
            <span>
              {editBlog ? (
                navPreview ? (
                  <>
                    <button onClick={handleSubmit}> Save</button>
                    <button onClick={() => setNavPreview(false)}> Edit</button>
                    <button
                      onClick={() => {
                        setEditBlog('');
                      }}
                    >
                      {' '}
                      Back
                    </button>
                  </>
                ) : (
                  <>
                    <button onClick={handleSubmit}> Save</button>
                    <button onClick={() => setNavPreview(true)}>
                      {' '}
                      Preview
                    </button>
                    <button
                      onClick={() => {
                        setEditBlog('');
                        setNavPreview(true);
                      }}
                    >
                      {' '}
                      Back
                    </button>
                  </>
                )
              ) : (
                <button
                  onClick={() => {
                    setEditBlog(-1);
                    setNavPreview(false);
                  }}
                >
                  {' '}
                  + Create Blog
                </button>
              )}
            </span>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card__body">
                  {editBlog ? (
                    navPreview ? (
                      <Preview form={form} tags={tags} />
                    ) : (
                      <Edit
                        form={form}
                        handleChange={handleChange}
                        handleContentChange={handleContentChange}
                        handleImageChange={handleImageChange}
                        fileError={fileError}
                        removeSelectedFile={removeSelectedFile}
                        handleTagChange={handleTagChange}
                        updateTags={updateTags}
                        createTag={createTag}
                        removeTag={removeTag}
                        tagResponseMsg={tagResponseMsg}
                        tags={tags}
                        showTagFields={showTagFields}
                        setShowTagFields={setShowTagFields}
                        setBlogImageTracker={setBlogImageTracker}
                        blogImageTracker={blogImageTracker}
                      />
                    )
                  ) : (
                    <Table
                      limit={limit}
                      headData={TableHead}
                      renderHead={(item, index) => renderHead(item, index)}
                      bodyData={blogList}
                      renderBody={(item, index, currPage) =>
                        renderBody(
                          item,
                          index,
                          setEditBlog,
                          setNavPreview,
                          handleClick,
                          currPage,
                          renderTooltip
                        )
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <ScaleLoader
          color="#36d7b7"
          style={{
            display: 'flex',
            height: '100vh',
            width: '100vw',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        />
      )}
    </div>
  );
};

export default Blogs;

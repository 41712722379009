import React from 'react';

import { Route, Switch } from 'react-router-dom'
import Blogs from '../pages/Blogs'
import Resources from '../pages/Resources'
import Videos from '../pages/Videos'
import Dashboard from '../pages/DashBoard'
import FAQ from '../pages/FAQ';
import Site from '../pages/Site'

const Routes = () => {
    return (
        <Switch>
            <Route path='/site' render={() => <Site />}/>
            <Route path='/laws' render={() => <Resources resourceName="laws" />}/>
            <Route path='/research' render={() => <Resources resourceName="research" />}/>
            <Route path='/learning-documents' render={() => <Resources resourceName="learningDocs" />}/>
            <Route path='/videos' render={() => <Videos/>}/>
            <Route path='/blogs' render={() => <Blogs/>}/>
            <Route path='/articles' render={() => <Resources resourceName="articles" />}/>
            <Route path="/faq" render={() => <FAQ />} />
            <Route exact path='/' render={() => <Dashboard/>}/>
        </Switch>
    )
}

export default Routes;

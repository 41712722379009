const Edit = ({ handleChange, handleFileChange, form, fileError }) => {
  return (
    <>
      <div className="mb-3">
        <label htmlFor="Title" className="form-label">
          Title *
        </label>
        <input
          type="text"
          className="form-control"
          id="Title"
          name="title"
          placeholder=""
          value={form.title}
          onChange={handleChange}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="file" className="form-label">
          File *
        </label>
        <input
          type="file"
          className="form-control"
          id="file"
          name="file"
          accept=".pdf"
          multiple={false}
          onChange={handleFileChange}
        />
        <p style={{ color: 'red' }}>{fileError}</p>
      </div>
      {form.file ? (
        <div className="mb-3">
          <label htmlFor="previewFile" className="form-label">
            File Preview
          </label>
          <iframe
            id="previewFile"
            src={
              typeof form.file === 'string'
                ? form.file
                : URL.createObjectURL(form.file)
            }
            alt=""
            style={{ height: '10em', width: 'auto' }}
          />
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default Edit;

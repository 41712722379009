import axios from 'axios';

// axios instance for making requests
const axiosInstance = axios.create();

// request interceptor for adding token
axiosInstance.interceptors.request.use((config) => {
  // add token to request headers
  config.url =
    (process.env.REACT_APP_API || "http://localhost:8000") + config.url;
  config.withCredentials = true;
  return config;
});

// response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // add token to request headers
    if (response.status === 200) {
      return response;
    } else {
      throw Error("An error occurred");
    }
  },
  (err) => {
    if (err.code === "ERR_NETWORK") {
      throw Error("Server Down");
    } else if (err.response?.data?.message === "UnAuthorized") {
      window.location.reload();
    } else {
      return err.response;
    }
  }
);

export default axiosInstance; 
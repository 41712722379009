import React, { useEffect, useState } from 'react';
import './topnav.css';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Dropdown from '../dropdown/Dropdown';

// import ThemeMenu from '../thememenu/ThemeMenu'

import user_menu from '../../assets/JsonData/user_menus.json';
import { useHistory } from 'react-router-dom';
import axiosInstance from '../../utility/axios';

const renderUserToggle = (user) => (
  <div className="topnav__right-user">
    <div className="topnav__right-user__name">
      <i className="bx bxs-cog" style={{ fontSize: '1.5rem' }}></i>
    </div>
  </div>
);

const renderUserMenu = (item, index, handler) => (
  <Link to="/" key={index}>
    <div className="notification-item" onClick={handler[item.handler]}>
      <i className={item.icon}></i>
      <span>{item.content}</span>
    </div>
  </Link>
);

const Topnav = () => {
  const Reducer = useSelector((state) => state.Reducer);
  const history = useHistory();

    const [curr_user, setCurr_user] = useState({
        display_name: 'User Name',
        image: "user_image"
    })
    useEffect(()=>{
        setCurr_user({...curr_user, display_name: Reducer.user.email})
    }, [])
    
  const logout = () => {
    axiosInstance.post('/logout').then((response) => {
      if (response.status === 200) {
        window.location.reload();
      } else {
        // TODO
      }
    });
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Logout
    </Tooltip>
  );

  const handler = {
    logout: logout,
  };

  return (
    <div className="topnav__right">
      <h5>{curr_user.display_name}</h5>
      {/* <div className="topnav__right-item" style={{padding: "1em"}}>
                    <Dropdown
                        customToggle={() => renderUserToggle(curr_user)}
                        contentData={user_menu}
                        renderItems={(item, index) => renderUserMenu(item, index, handler)}
                    />
                </div> */}

      <OverlayTrigger
        placement="bottom"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip}
      >
        <i
          className="bx bx-log-out"
          onClick={logout}
          style={{
            fontSize: '1.5rem',
            cursor: 'pointer',
            color: 'white',
            margin: '.5em',
          }}
        ></i>
      </OverlayTrigger>
    </div>
  );
};

export default Topnav;

import { useEffect } from 'react';
import './preview.css';

const Preview = ({ form }) => {
  return (
    <>
      <div className="blog-preview">
        <hr />
        <div style={{ paddingTop: '1em', fontWeight: '600' }}>
          {form.question_en}
        </div>
        <div style={{ paddingTop: '0.8em', fontSize: '14px' }}>
          <div dangerouslySetInnerHTML={{ __html: form.answer_en }}></div>
        </div>
        <br />
        <div style={{ paddingTop: '1em', fontWeight: '600' }}>
          {form.question_hi}
        </div>
        <div style={{ paddingTop: '0.8em', fontSize: '14px' }}>
          <div dangerouslySetInnerHTML={{ __html: form.answer_hi }}></div>
        </div>
        <br />
        <h4
          style={{
            marginBottom: '25px',
          }}
        >
          Categories:
        </h4>
        <div style={{ display: 'flex' }}>
          <div style={{ marginRight: '20px' }}>{form.tag_en}</div>
          <div>{form.tag_hi}</div>
        </div>
      </div>
    </>
  );
};

export default Preview;

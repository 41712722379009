import React, { useState } from "react";
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import axiosInstance from "../utility/axios"
import Action from '../redux/actions/Action'
import Bg from '../assets/images/loginBg.svg'

import "../assets/css/login.css";

const Login = () => {
  const dispatch = useDispatch()
  const [user, setUser] = useState({
    email: "",
    password: ""
  })
  const [response, setResponse] = useState("")
  const handleChange = (e) => {
    setUser({...user, [e.target.name]: e.target.value})
  }
  const history = useHistory();
  const handleSubmit = (e) => {
    e.preventDefault()
    axiosInstance.post('/login', {
      email: user.email,
      password: user.password
    }).then(response=> {
      setResponse(response.data.message)
      if (response.status === 200) {
        dispatch(Action.setUser({email: response.data?.email, isAuth: true}))
        history.push("/");
      } else {
        dispatch(Action.setUser({email: "", isAuth: false}))
      }
    }).catch((err)=> {
      setResponse(err)
    })
  }

  return (
    <>
    <div className="login-container">
      <span style={{ display: "flex", justifyContent: "space-between"}}>
      <h1>Login</h1>
      <div className="login-bg">
        <img src={Bg} alt="" />
      </div>
      </span>
      <form>
        <div className="form-group">
          <label htmlFor="email">Email address</label>
          <input type="email" className="form-control" id="email" aria-describedby="emailHelp" placeholder="Enter email" value={user.email} name="email" onChange={handleChange} />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input type="password" className="form-control" id="password" placeholder="Password" value={user.password} name="password" onChange={handleChange} />
        </div>
        <p style={{color: "red"}}>{response}</p>
        <div className="login-btn">
          <button type="submit" className="btn btn-primary" onClick={handleSubmit}>Login</button>
        </div>
      </form>
      
    </div>
    
    </>
  );
}

export default Login;

// import Chart from 'react-apexcharts'
import axiosInstance from '../utility/axios';

import StatusCard from '../components/status-card/StatusCard';

import DashboardBg from '../assets/images/Headless_CMS.jpg';

import statusCardsInitData from '../assets/JsonData/status-card-data.json';
import { useEffect, useState } from 'react';

const chartOptions = {
  series: [
    {
      name: 'Resource',
      data: [2.3, 3.1, 4.0, 10.1, 4.0, 3.6],
      color: '#008B8B',
    },
  ],
  options: {
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: false,
      },
    },
    legend: {
      show: true,
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val;
      },
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      categories: [
        'Laws',
        'Research',
        'Learning Documents',
        'Videos',
        'Blogs',
        'Articles',
      ],
      position: 'bottom',
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: ['#444'],
          fontSize: '14px',
          fontFamily: 'Helvetica, Arial, sans-serif',
        },
      },
      tooltip: {
        enabled: true,
      },
    },
    title: {
      text: 'Resources Available',
      align: 'center',
      style: {
        fontSize: '28px',
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 'bold',
        color: '#3F3F3F',
        margin: '1em',
      },
    },
  },
};

const Dashboard = () => {
  const [statusCards, setStatusCards] = useState({ ...statusCardsInitData });
  useEffect(async () => {
    const blogsCount = await axiosInstance.get('/blogs/blogsCount', {
      headers: {
        isadmin: true,
      },
    });
    const videosCount = await axiosInstance.get('/videos/videosCount', {
      headers: {
        isadmin: true,
      },
    });
    const articlesCount = await axiosInstance.get(`/articles/resourceCount`, {
      headers: {
        isadmin: true,
      },
    });
    const lawsCount = await axiosInstance.get(`/laws/resourceCount`, {
      headers: {
        isadmin: true,
      },
    });
    const researchCount = await axiosInstance.get(`/research/resourceCount`, {
      headers: {
        isadmin: true,
      },
    });
    const faqCount = await axiosInstance.get(`/faq/faqCount`, {
      headers: {
        isadmin: true,
      },
    });
    const learningDocsCount = await axiosInstance.get(
      `/learningDocs/resourceCount`,
      {
        headers: {
          isadmin: true,
        },
      }
    );

    setStatusCards({
      ...statusCards,
      blogs: {
        ...statusCards.blogs,
        count: blogsCount.data.response,
      },
      laws: {
        ...statusCards.laws,
        count: lawsCount.data.response,
      },
      research: {
        ...statusCards.research,
        count: researchCount.data.response,
      },
      learningDocs: {
        ...statusCards.learningDocs,
        count: learningDocsCount.data.response,
      },
      videos: {
        ...statusCards.videos,
        count: videosCount.data.response,
      },
      faq: {
        ...statusCards.faq,
        count: faqCount.data.response,
      },
      articles: {
        ...statusCards.articles,
        count: articlesCount.data.response,
      },
    });
  }, []);

  return (
    <div>
      <h2 className="page-header">Dashboard</h2>
      <div
        className="row"
        style={{
          justifyContent: 'flex-end',
          backgroundImage: `url(${DashboardBg})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPositionY: 'center',
        }}
      >
        <div className="col-6 col-sm-12">
          <div className="row" style={{ padding: '.5em' }}>
            {Object.values(statusCards).map((item, index) => (
              <div className="col-6" key={index} style={{ padding: '0 5px' }}>
                <StatusCard
                  icon={item.icon}
                  count={item.count}
                  title={item.title}
                />
              </div>
            ))}
          </div>
        </div>

        {/* <div className="col-6 col-sm-12">
                    <div className="card full-height">
                        <Chart
                            options={{
                                ...chartOptions.options,
                            }}
                            series={chartOptions.series}
                            type='bar'
                            height='100%'
                        />
                    </div>
                </div> */}
      </div>
    </div>
  );
};

export default Dashboard;

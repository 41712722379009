
const Preview = ({form, convertYouTubeUrlToEmbed}) => {
    const { embedURL, thumbnailUrl } = convertYouTubeUrlToEmbed(form.url)
    return (
        <>
        <div className="mb-3">
            <h1>{form.title}</h1>
        </div>
        {
            embedURL ? 
        <div className="mb-3">
                <iframe
                    id="previewVideo"
                  src={embedURL}
                  alt=""
                  style={{ height: "20em", width: "30em" }}
                />
        </div>
              : ""
        }
        <div className="mb-3">
            <h6>{form.date}</h6>
        </div>
        <div className="mb-3">
            Thumbnail: <br/>
            <img src={thumbnailUrl} alt="" width={"40%"} />
        </div>
        
        <div className="mb-3">
            Description: <br/>
            <h5>{form.description}</h5>
        </div>
        </>
    )
}


export default Preview;
import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import Alert from "react-bootstrap/Alert";
import PreviewParagraph from "./PreviewText";
import axiosInstance from "../../utility/axios";

const EditText = ({ lang }) => {
  const [responseMsg, setResponseMsg] = useState("");
  const [preview, setPreview] = useState({
    aboutMyAmbar: true,
    aboutMyAmbarSurakshaChakra: true,
    aboutVodafoneIdeaFoundation: true,
    aboutMarthaFarrell: true,
  });

  const [isSaved, setIsSaved] = useState({
    aboutMyAmbar: true,
    aboutMyAmbarSurakshaChakra: true,
    aboutVodafoneIdeaFoundation: true,
    aboutMarthaFarrell: true,
  });

  const [about, setAbout] = useState({
    aboutMyAmbar: {
      en: "",
      hi: "",
    },
    aboutMyAmbarSurakshaChakra: {
      en: "",
      hi: "",
    },
    aboutVodafoneIdeaFoundation: {
      en: "",
      hi: "",
    },
    aboutMarthaFarrell: {
      en: "",
      hi: "",
    },
  });

  const titleMap = {
    aboutMyAmbar: {
      en: "MyAmbar",
      hi: "माय अंबर",
      img: "images/about3.svg",
    },
    aboutMyAmbarSurakshaChakra: {
      en: "MyAmbar Suraksha Chakra",
      hi: "माय अंबर सुरक्षा चक्र",
      img: "images/about1.svg",
    },
    aboutVodafoneIdeaFoundation: {
      en: "Vodafone Idea Foundation",
      hi: "वोडाफोन आइडिया फाउंडेशन",
      img: "images/about5.svg",
    },
    aboutMarthaFarrell: {
      en: "Martha Farrell Foundation",
      hi: "मार्था फैरेल फाउंडेशन",
      img: "images/about2.svg",
    },
  };

  const getAbout = () => {
    axiosInstance
      .get(`/site/getAbout`, {
        headers: {
          isadmin: true,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setAbout({ ...response.data?.response });
        } else {
          // TODO
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getAbout();
    return () => {
      setAbout({});
    };
  }, []);

  const PreviewAbout = ({ text, titleKey }) => {
    return (
      <div
        className="sm:flex items-center"
        data-aos="slide-left"
        data-aos-offset="100"
        data-aos-easing="ease"
        data-aos-duration="900"
      >
        <div className="sm p-5">
          <div className="text">
            <h2 className="mb-4 font-bold text-3xl  sm:text-4xl ">
              {lang === "en" && "About "}
              <span className="gradient__text">{titleMap[titleKey][lang]}</span>
            </h2>
            <PreviewParagraph text={text} wordLimit={100} />
          </div>
        </div>
      </div>
    );
  };

  const handleChange = (event, editor, key) => {
    setAbout({ ...about, [key]: { ...about[key], [lang]: editor.getData() } });
    if (isSaved[key] === true) {
      setIsSaved({ ...isSaved, [key]: false });
    }
  };

  const handleSave = (key) => {
    axiosInstance
      .post(`/site/updateAbout`, {
        lang,
        key,
        value: about[key][lang],
      })
      .then((response) => {
        if (response.status === 200) {
          getAbout();
          setIsSaved({ ...isSaved, [key]: true });
          setPreview({ ...preview, [key]: true });
        } else {
          // TODO
        }
        setResponseMsg(response.data?.message);
      })
      .catch((err) => {
        console.log(err);
        setResponseMsg(err);
      });
  };

  useEffect(() => {
    const allSaved = Object.keys(isSaved).reduce(
      (acc, curr) => isSaved[curr] && 1,
      1
    );
    Object.keys(isSaved).forEach((key) => {
      if (!isSaved[key]) {
        document.getElementById(key).style.border = "2px solid red";
      } else {
        document.getElementById(key).style.border = "none";
      }
    });
  }, [isSaved]);

  return (
    <div>
      {responseMsg && (
        <Alert variant="info" onClose={() => setResponseMsg("")} dismissible>
          <p>{responseMsg}</p>
        </Alert>
      )}
      <div className="card site-settings" id="aboutMyAmbarSurakshaChakra">
        <div className="site-settings-header">
          <h4 className="site-settings-banner">MyAmbar Suraksha Chakra</h4>
          <div className="banner-btns">
            <button
              className="banner-btn"
              style={{ marginRight: "10px" }}
              onClick={() =>
                setPreview({
                  ...preview,
                  aboutMyAmbarSurakshaChakra:
                    !preview.aboutMyAmbarSurakshaChakra,
                })
              }
            >
              {preview.aboutMyAmbarSurakshaChakra ? "Edit" : "Preview"}
            </button>
            <button
              className="banner-btn"
              onClick={() => handleSave("aboutMyAmbarSurakshaChakra")}
            >
              Save
            </button>
          </div>
        </div>
        {!isSaved.aboutMyAmbarSurakshaChakra && (
          <Alert variant="danger">
            <p>Please save before moving forward else data might erase.</p>
          </Alert>
        )}

        {!preview.aboutMyAmbarSurakshaChakra ? (
          <CKEditor
            editor={Editor}
            data={about.aboutMyAmbarSurakshaChakra[lang]}
            onChange={(event, editor) =>
              handleChange(event, editor, "aboutMyAmbarSurakshaChakra")
            }
            onReady={(editor) => {
              editor.ui.view.toolbar.items.get(11).buttonView.isVisible = false;
              // editor.ui.view.toolbar.items.get(16).buttonView.isVisible = false;
              editor.ui.view.toolbar.items.get(31).isVisible = false;
              editor.ui.view.toolbar.items.get(32).buttonView.isVisible = false;
              editor.ui.view.toolbar.items.get(33).buttonView.isVisible = false;
              editor.ui.view.toolbar.items.get(34).buttonView.isVisible = false;
              editor.ui.view.toolbar.items.get(35).buttonView.isVisible = false;
            }}
          />
        ) : (
          <PreviewAbout
            text={about.aboutMyAmbarSurakshaChakra[lang]}
            titleKey="aboutMyAmbarSurakshaChakra"
          />
        )}
      </div>
      <div className="card site-settings" id="aboutMyAmbar">
        <div className="site-settings-header">
          <h4 className="site-settings-banner">About MyAmbar</h4>
          <div className="banner-btns">
            <button
              className="banner-btn"
              style={{ marginRight: "10px" }}
              onClick={() =>
                setPreview({ ...preview, aboutMyAmbar: !preview.aboutMyAmbar })
              }
            >
              {preview.aboutMyAmbar ? "Edit" : "Preview"}
            </button>
            <button
              className="banner-btn"
              onClick={() => handleSave("aboutMyAmbar")}
            >
              Save
            </button>
          </div>
        </div>
        {!isSaved.aboutMyAmbar && (
          <Alert variant="danger">
            <p>Please save before moving forward else data might erase.</p>
          </Alert>
        )}
        {!preview.aboutMyAmbar ? (
          <CKEditor
            editor={Editor}
            data={about.aboutMyAmbar[lang]}
            onChange={(event, editor) =>
              handleChange(event, editor, "aboutMyAmbar")
            }
            onReady={(editor) => {
              editor.ui.view.toolbar.items.get(11).buttonView.isVisible = false;
              // editor.ui.view.toolbar.items.get(16).buttonView.isVisible = false;
              editor.ui.view.toolbar.items.get(31).isVisible = false;
              editor.ui.view.toolbar.items.get(32).buttonView.isVisible = false;
              editor.ui.view.toolbar.items.get(33).buttonView.isVisible = false;
              editor.ui.view.toolbar.items.get(34).buttonView.isVisible = false;
              editor.ui.view.toolbar.items.get(35).buttonView.isVisible = false;
            }}
          />
        ) : (
          <PreviewAbout
            text={about.aboutMyAmbar[lang]}
            titleKey="aboutMyAmbar"
          />
        )}
      </div>
      <div className="card site-settings" id="aboutVodafoneIdeaFoundation">
        <div className="site-settings-header">
          <h4 className="site-settings-banner">
            About Vodafone Idea Foundation
          </h4>
          <div className="banner-btns">
            <button
              className="banner-btn"
              style={{ marginRight: "10px" }}
              onClick={() =>
                setPreview({
                  ...preview,
                  aboutVodafoneIdeaFoundation:
                    !preview.aboutVodafoneIdeaFoundation,
                })
              }
            >
              {preview.aboutVodafoneIdeaFoundation ? "Edit" : "Preview"}
            </button>
            <button
              className="banner-btn"
              onClick={() => handleSave("aboutVodafoneIdeaFoundation")}
            >
              Save
            </button>
          </div>
        </div>
        {!isSaved.aboutVodafoneIdeaFoundation && (
          <Alert variant="danger">
            <p>Please save before moving forward else data might erase.</p>
          </Alert>
        )}
        {!preview.aboutVodafoneIdeaFoundation ? (
          <CKEditor
            editor={Editor}
            data={about.aboutVodafoneIdeaFoundation[lang]}
            onChange={(event, editor) =>
              handleChange(event, editor, "aboutVodafoneIdeaFoundation")
            }
            onReady={(editor) => {
              editor.ui.view.toolbar.items.get(11).buttonView.isVisible = false;
              // editor.ui.view.toolbar.items.get(16).buttonView.isVisible = false;
              editor.ui.view.toolbar.items.get(31).isVisible = false;
              editor.ui.view.toolbar.items.get(32).buttonView.isVisible = false;
              editor.ui.view.toolbar.items.get(33).buttonView.isVisible = false;
              editor.ui.view.toolbar.items.get(34).buttonView.isVisible = false;
              editor.ui.view.toolbar.items.get(35).buttonView.isVisible = false;
            }}
          />
        ) : (
          <PreviewAbout
            text={about.aboutVodafoneIdeaFoundation[lang]}
            titleKey="aboutVodafoneIdeaFoundation"
          />
        )}
      </div>
      <div className="card site-settings" id="aboutMarthaFarrell">
        <div className="site-settings-header">
          <h4 className="site-settings-banner">
            About Martha Farrell Foundation
          </h4>
          <div className="banner-btns">
            <button
              className="banner-btn"
              style={{ marginRight: "10px" }}
              onClick={() =>
                setPreview({
                  ...preview,
                  aboutMarthaFarrell: !preview.aboutMarthaFarrell,
                })
              }
            >
              {preview.aboutMarthaFarrell ? "Edit" : "Preview"}
            </button>
            <button
              className="banner-btn"
              onClick={() => handleSave("aboutMarthaFarrell")}
            >
              Save
            </button>
          </div>
        </div>
        {!isSaved.aboutMarthaFarrell && (
          <Alert variant="danger">
            <p>Please save before moving forward else data might erase.</p>
          </Alert>
        )}
        {!preview.aboutMarthaFarrell ? (
          <CKEditor
            editor={Editor}
            data={about.aboutMarthaFarrell[lang]}
            onChange={(event, editor) =>
              handleChange(event, editor, "aboutMarthaFarrell")
            }
            onReady={(editor) => {
              editor.ui.view.toolbar.items.get(11).buttonView.isVisible = false;
              // editor.ui.view.toolbar.items.get(16).buttonView.isVisible = false;
              editor.ui.view.toolbar.items.get(31).isVisible = false;
              editor.ui.view.toolbar.items.get(32).buttonView.isVisible = false;
              editor.ui.view.toolbar.items.get(33).buttonView.isVisible = false;
              editor.ui.view.toolbar.items.get(34).buttonView.isVisible = false;
              editor.ui.view.toolbar.items.get(35).buttonView.isVisible = false;
            }}
          />
        ) : (
          <PreviewAbout
            text={about.aboutMarthaFarrell[lang]}
            titleKey="aboutMarthaFarrell"
          />
        )}
      </div>
    </div>
  );
};

export default EditText;


const BlogCard = ({blog}) => {

    const share = (e) => {
        Array.from(document.getElementsByClassName('share-icons')).map(
          (ele) => {
            if (!ele.classList.contains('active')) {
              ele.classList.add('active');
            } else {
              ele.classList.remove('active');
            }
          }
        );
      };

    return <div className="card" key={blog.id}>
    <div className="card-left">
      <div
        className="card-img"
        style={{
          backgroundImage: `url(${typeof blog.demoImage === "string" ? blog.demoImage : URL.createObjectURL(blog.demoImage)})`,
        }}
      >
        <div className="floating-share">
          <button className="share-btn" id="share-btn">
            <i
              className="fa fa-share-alt"
              id={blog.id}
              onClick={share}
            ></i>
          </button>
          <div className="share-icons">
            <a
              className="share-icon"
              href={
                'https://twitter.com/share?url=' +
                encodeURIComponent(
                  window.origin + '/blog/' + blog.shareLink
                )
              }
              target="_blank"
            >
              <i className="fa-brands fa-twitter"></i>
            </a>
            <a
              className="share-icon"
              href={
                'https://www.facebook.com/share.php?u=' +
                encodeURIComponent(
                  window.origin + '/blog/' + blog.shareLink
                )
              }
              target="_blank"
            >
              <i className="fa-brands fa-facebook"></i>
            </a>
            <a
              className="share-icon"
              href={
                'https://www.linkedin.com/shareArticle?url=' +
                encodeURIComponent(
                  window.origin + '/blog/' + blog.shareLink
                )
              }
              target="_blank"
            >
              <i className="fa-brands fa-linkedin"></i>
            </a>
            <a
              className="share-icon"
              href={
                'whatsapp://send?text=' +
                encodeURIComponent(
                  String(window.origin + '/blog/' + blog.shareLink)
                )
              }
              data-action="share/whatsapp/share"
              target="_blank"
            >
              <i className="fa-brands fa-whatsapp"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div className="card-right">
      <div
        className="card-title fw-7"
        style={{ cursor: 'pointer' }}
      >
        {blog.title}
      </div>
      {/*<div className="card-text fw-5">{blog.shortDesp}</div>*/}
      <div className="card-right-body">
        <div className="card-rb-1">
          <i className="fas fa-user-alt fa-2x"></i>
        </div>
        <div className="card-rb-2 ">
          <div className="card-rb-2-title fw-7">{blog.authors}</div>
          <div className="card-rb-2-text fw-5">{blog.date}</div>
        </div>
      </div>
    </div>
  </div>
}

export default BlogCard
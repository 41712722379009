import BlogCard from './previewBlogCard';
import './preview.css';

const Preview = ({ form, tags }) => {
  return (
    <div className="blog-preview">
      <div className="blog-card">
        <BlogCard blog={form} />
      </div>
      <hr />
      <div style={{ paddingTop: '1em' }}>
        <div
          className="w-100"
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            backgroundColor: '#dddddd',
          }}
        >
          <img
            className=""
            style={{ maxHeight: '18em' }}
            src={
              typeof form.bannerImage === 'string'
                ? form.bannerImage
                : URL.createObjectURL(form.bannerImage)
            }
          />
        </div>
        <div
          style={{ maxWidth: '700px', top: form.bannerImage ? '-80px' : '' }}
          className="mx-auto text-secondary position-relative"
        >
          <div className="text-center mb-4">
            {form.authorImage && (
              <img
                src={
                  typeof form.authorImage === 'string'
                    ? form.authorImage
                    : URL.createObjectURL(form.authorImage)
                }
                style={{
                  width: '120px',
                  border: '5px solid #eee',
                  backgroundColor: 'white',
                }}
                className="rounded-circle mx-auto text-center"
              />
            )}

            <div>
              <small>
                <p href="#" className="text-primary">
                  {typeof form.tag === 'string'
                    ? form.tag
                    : form.tag?.join(', ')}
                </p>
              </small>
            </div>
            <h1 className="fs-4 text-center font-weight-bold text-dark">
              {form.title}
            </h1>
            {form.authors && (
              <div>
                <small className="text-dark">
                  Written by{' '}
                  <p href="#" className="text-primary">
                    {form.authors}
                  </p>
                </small>
              </div>
            )}
          </div>

          <div dangerouslySetInnerHTML={{ __html: form.content }}></div>

          <br />
          {form.source && (
            <div className="my-3">
              <small>
                <a target="_blank" href={form.source} className="text-primary">
                  Original Source
                </a>
              </small>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Preview;


const Edit = ({handleChange, form}) => {
    return (
        <>
        <div className="mb-3">
            <label htmlFor="Title" className="form-label">Title *</label>
            <input type="text" className="form-control" id="Title" name="title" placeholder="" value={form.title} onChange={handleChange} />
        </div>
        <div className="mb-3">
            <label htmlFor="URL" className="form-label">Video URL *</label>
            <input type="text" className="form-control" id="URL" name="url" placeholder="" value={form.url} onChange={handleChange} />
        </div>
        <div className="mb-3">
            <label htmlFor="Date" className="form-label">Date *</label>
            <input type="date" className="form-control" id="Date" name="date" placeholder="" value={form.date} onChange={handleChange} />
        </div>
        <div className="mb-3">
            <label htmlFor="Description" className="form-label">Description *</label>
            <input type="text" className="form-control" id="Description" name="description" placeholder="" value={form.description} onChange={handleChange} />
        </div>  
        </>
    )
}

export default Edit;
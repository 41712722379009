
const Preview = ({form}) => {
    return (
        <>
        <div className="mb-3">
            <h1>{form.title}</h1>
        </div>
        {
            form.file ? 
        <div className="mb-3">
                <iframe
                    id="previewFile"
                  src={typeof form.file === "string" ? form.file : URL.createObjectURL(form.file)}
                  alt=""
                  style={{ height: "40em", width: "100%" }}
                />
        </div>
              : ""
        }
        
        </>
    )
}


export default Preview;
import React, {useEffect, useState} from 'react'
import ScaleLoader from "react-spinners/ScaleLoader";

import './layout.css'

import Sidebar from '../sidebar/Sidebar'

import Routes from '../Routes'

import { BrowserRouter, Route, Redirect } from 'react-router-dom'

import { useSelector, useDispatch } from 'react-redux'

import Action from '../../redux/actions/Action'
import Login from '../../pages/Login'
import axiosInstance from '../../utility/axios'

const Layout = () => {
    const Reducer = useSelector(state => state.Reducer)
    const [loader, setLoader] = useState(true)
    const dispatch = useDispatch()

    useEffect(() => {
        const themeClass = localStorage.getItem('themeMode', 'theme-mode-light')

        const colorClass = localStorage.getItem('colorMode', 'theme-mode-light')

        dispatch(Action.setMode(themeClass))

        dispatch(Action.setColor(colorClass))
    }, [dispatch])

    useEffect(()=> {
        axiosInstance.post("/login", {}).then((response)=> {
            if (response.status === 200) {
                dispatch(Action.setUser({email: response.data?.email, isAuth: true}))
            } else {
                dispatch(Action.setUser({email: "", isAuth: false}))
            }
            setLoader(false)
        }).catch((err)=> {
            console.log(err)
          })
    },[])

    return (
        <BrowserRouter>
            {
                !loader ? <>
                <Route render={(props) => (
                    Reducer?.user?.isAuth ? 
                    <div className={`layout ${Reducer.mode} ${Reducer.color}`}>
                        <Sidebar {...props}/>
                        <div className="layout__content">
                            <div className="layout__content-main">
                                <Routes/>
                            </div>
                        </div>
                    </div>
                    : <Redirect to="/login"></Redirect>
                )}/>
                <Route path='/login' exact component={Login}/>
                </> : 
                <ScaleLoader color="#36d7b7" style={{
                    display: "flex",
                    height: "100vh",
                    width: "100vw",
                    alignItems: "center",
                    justifyContent: "center",
                }}/>
            }
            
        </BrowserRouter>
    )
}

export default Layout

import React, { useState } from 'react';
import axiosInstance from '../utility/axios';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Table from '../components/table/Table';
import Edit from '../components/faq/edit';
import Preview from '../components/faq/preview';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { useEffect } from 'react';

let limit = 5;
const TableHead = [
  '',
  'Question',
  'Answer',
  // 'short description',
  '',
  '',
  '',
];

const renderHead = (item, index) => <th key={index}>{item}</th>;

const renderBody = (
  item,
  index,
  setEditFaq,
  setNavPreview,
  handleClick,
  currPage,
  renderTooltip
) => (
  <tr key={index}>
    <td>{currPage * limit + index + 1}</td>
    <td>{item.question_en}</td>
    <td>{item.answer_en}</td>
    {/* <td>{item.shortDesp}</td> */}
    <td>
      <OverlayTrigger
        placement="bottom"
        delay={{ show: 250, hide: 400 }}
        overlay={(props) => renderTooltip(props, 'Preview')}
      >
        <i
          className="bx bxs-show"
          style={{ cursor: 'pointer', fontSize: '30px' }}
          onClick={() => {
            setNavPreview(true);
            setEditFaq(item.id);
          }}
        ></i>
      </OverlayTrigger>
    </td>
    <td>
      <OverlayTrigger
        placement="bottom"
        delay={{ show: 250, hide: 400 }}
        overlay={(props) => renderTooltip(props, 'Edit')}
      >
        <i
          className="bx bxs-edit-alt"
          onClick={() => {
            setNavPreview(false);
            setEditFaq(item.id);
          }}
          style={{ cursor: 'pointer', fontSize: '30px' }}
        ></i>
      </OverlayTrigger>
    </td>
    <td>
      <OverlayTrigger
        placement="bottom"
        delay={{ show: 250, hide: 400 }}
        overlay={(props) => renderTooltip(props, 'Delete')}
      >
        <i
          className="bx bxs-trash"
          data-bs-toggle="modal"
          data-bs-target="#faq"
          style={{ cursor: 'pointer', fontSize: '30px' }}
          onClick={() => handleClick(item.id)}
        ></i>
      </OverlayTrigger>
    </td>
  </tr>
);

const FAQ = () => {
  const [faqList, setFaqList] = useState([]);
  const [tagResponseMsg, setTagResponseMsg] = useState('');
  const [showTagFields, setShowTagFields] = useState(false);
  const [editFaq, setEditFaq] = useState('');
  const [navPreview, setNavPreview] = useState(true);
  const [responseMsg, setResponseMsg] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [itemId, setItemId] = useState(-1);
  const [state, setState] = useState();
  const [fileError, setFileError] = useState('');
  const [loader, setLoader] = useState(false);
  const [tags, setTags] = useState([]);
  const [lang, setLang] = useState(true);
  const [form, setForm] = useState({
    id: '',
    question_en: '',
    answer_en: '',
    tag_en: '',
    question_hi: '',
    answer_hi: '',
    tag_hi: '',
    tag_id: '',
  });
  const [tagForm, setTagForm] = useState({
    tagName_en: '',
    tagName_hi: '',
    tagImage: '',
  });

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleTagFormChange = (e) => {
    setTagForm({ ...tagForm, [e.target.name]: e.target.value });
  };

  const renderTooltip = (props, text) => (
    <Tooltip id="button-tooltip" {...props}>
      {text}
    </Tooltip>
  );

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.match('image.*') && file.size <= 5000000) {
      setTagForm({ ...tagForm, [e.target.name]: file });
      setFileError({ ...fileError, [e.target.name]: '' });
    } else {
      setFileError({
        ...fileError,
        [e.target.name]: 'File can have max size of 5MB',
      });
    }
  };

  const removeSelectedFile = (name) => {
    const fileInput = document.getElementById(name);
    fileInput.value = '';
    setTagForm({ ...tagForm, [name]: '' });
    setFileError({ ...fileError, [name]: '' });
  };

  const updateTags = () => {
    axiosInstance
      .get(`/faq/getTags`, {
        headers: {
          isadmin: true,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setTags([...response.data?.response]);
          setShowTagFields(false);
        } else {
          setTagResponseMsg('Unable to get Category');
        }
      })
      .catch((err) => {
        setTagResponseMsg(err);
      });
  };

  const createTag = (tagName_en, tagName_hi, file) => {
    const formData = new FormData();
    formData.append('tagName_en', tagName_en);
    formData.append('tagName_hi', tagName_hi);
    formData.append('tagImage', file);

    axiosInstance
      .post(`/faq/createTags/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        if (response.status === 200) {
          updateTags();
          setShowTagFields(false);
          setTagResponseMsg(response.data?.message);
          setTagForm({
            ...tagForm,
            tagName_en: '',
            tagName_hi: '',
            tagImage: '',
          });
        } else {
          setTagResponseMsg('Unable to create Category');
        }
      })
      .catch((err) => {
        setTagResponseMsg(err);
      });
  };

  const removeTag = (id, setShowModal) => {
    axiosInstance
      .delete(`/faq/deleteTags/${id}`)
      .then((response) => {
        if (response.status === 200) {
          updateTags();
          setShowTagFields(false);
          setTagResponseMsg(response.data?.message);
        } else {
          // TODO
          setTagResponseMsg('Unable to remove Tag');
        }
      })
      .catch((err) => {
        setResponseMsg(err);
      });
    setShowModal(false);
  };

  const handleContentChange = (event, editor, isEng) => {
    if (isEng) {
      setForm((prevForm) => ({ ...prevForm, answer_en: editor.getData() }));
    } else {
      setForm((prevForm) => ({ ...prevForm, answer_hi: editor.getData() }));
    }
  };

  const handleSubmit = (e) => {
    setLoader(true);
    e.preventDefault();
    const formData = new FormData();
    const selected_tag = tags.filter((obj) => obj.id == form.tag_id);

    formData.append('question_en', form.question_en);
    formData.append('answer_en', form.answer_en);
    formData.append('tag_en', selected_tag[0].name_en);
    formData.append('question_hi', form.question_hi);
    formData.append('answer_hi', form.answer_hi);
    formData.append('tag_hi', selected_tag[0].name_hi);

    if (form.id) {
      axiosInstance
        .patch(`/faq/update/${form.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setEditFaq('');
            updateTable();
            setLoader(false);
          } else {
            // TODO: alert
          }
          setResponseMsg(response.data?.message);
        })
        .catch((err) => {
          setResponseMsg(err);
        });
    } else {
      axiosInstance
        .post(`/faq/create`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setEditFaq('');
            updateTable();
            setLoader(false);
          } else {
            // TODO: alert
          }
          setResponseMsg(response.data?.message);
        })
        .catch((err) => {
          setResponseMsg(err);
        });
    }
  };

  const handleClick = (id) => {
    setItemId(id);
    setShowModal(true);
  };
  const handleDelete = (e, id) => {
    e.stopPropagation();
    axiosInstance
      .delete(`/faq/delete/${id}`)
      .then((response) => {
        if (response.status === 200) {
          updateTable();
        } else {
          // TODO: alert
        }
        setResponseMsg(response.data?.message);
      })
      .catch((err) => {
        setResponseMsg(err);
      });
    setShowModal(false);
  };

  useEffect(() => {
    if (editFaq === '' || editFaq === -1) {
      setForm({
        ...form,
        id: '',
        question_en: '',
        answer_en: '',
        tag_en: '',
        question_hi: '',
        answer_hi: '',
        tag_hi: '',
      });
      setTagForm({
        ...tagForm,
        tagName_en: '',
        tagName_hi: '',
        tagImage: '',
      });
      setFileError('');
    } else {
      const faq = faqList.filter((b) => b.id === editFaq)[0];
      setForm({
        ...form,
        id: faq?.id || '',
        question_en: faq?.question_en || '',
        answer_en: faq?.answer_en || '',
        tag_en: faq?.tag_en || '',
        question_hi: faq?.question_hi || '',
        answer_hi: faq?.answer_hi || '',
        tag_hi: faq?.tag_hi || '',
      });
    }
  }, [editFaq]);

  const updateTable = () => {
    axiosInstance
      .get(`/faq/readAll`, {
        headers: {
          isadmin: true,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setFaqList([...response.data?.response]);
        } else {
          // TODO
        }
      })
      .catch((err) => {
        setResponseMsg(err);
      });
  };

  useEffect(() => {
    updateTable();
    updateTags();
    return () => {
      setState({});
    };
  }, []);

  useEffect(() => {
    if (responseMsg) addAlert();
  }, [responseMsg]);

  const addAlert = () => {
    document.getElementById('api-response')?.remove();
    const parentDiv = document.getElementById('faq-container');
    const childDiv = document.createElement('div');
    childDiv.className = 'alert alert-info alert-dismissible fade show';
    childDiv.role = 'alert';
    childDiv.id = 'api-response';
    childDiv.innerHTML =
      responseMsg +
      '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>';

    parentDiv.insertBefore(childDiv, parentDiv.firstChild);
  };

  return (
    <div id="faq-container">
      {!loader ? (
        <>
          <div
            className={`modal ${showModal ? 'show' : ''} fade`}
            tabIndex="-1"
            style={{ display: showModal ? 'block' : 'none' }}
            id="faq"
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-lg modal-dialog-centered"
              role="document"
            >
              <div className="modal-content">
                <div
                  className="modal-header"
                  style={{ backgroundColor: '#008b8b' }}
                >
                  <h5 className="modal-title" style={{ color: 'white' }}>
                    Delete
                  </h5>
                  <button
                    className="btn-close btn-close-white"
                    type="button"
                    aria-label="Close"
                    data-bs-dismiss="modal"
                    onClick={() => setShowModal(false)}
                  ></button>
                </div>
                <div className="modal-body">
                  <div style={{ marginLeft: '1rem' }}>
                    Are you sure want to delete this item?
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    className="btn btn-light"
                    type="button"
                    id="TableModalClose"
                    data-bs-dismiss="modal"
                    onClick={() => setShowModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    name="next"
                    onClick={(e) => handleDelete(e, itemId)}
                    data-bs-dismiss="modal"
                    className="btn btn-primary action-button"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="page-header">
            <h2>FAQ's</h2>

            <span>
              {editFaq ? (
                navPreview ? (
                  <>
                    <button onClick={handleSubmit}> Save</button>
                    <button onClick={() => setNavPreview(false)}> Edit</button>
                    <button
                      onClick={() => {
                        setEditFaq('');
                      }}
                    >
                      {' '}
                      Back
                    </button>
                  </>
                ) : (
                  <>
                    <button onClick={handleSubmit}> Save</button>
                    <button onClick={() => setNavPreview(true)}>
                      {' '}
                      Preview
                    </button>
                    <button
                      onClick={() => {
                        setEditFaq('');
                        setNavPreview(true);
                      }}
                    >
                      {' '}
                      Back
                    </button>
                  </>
                )
              ) : (
                <button
                  onClick={() => {
                    setEditFaq(-1);
                    setNavPreview(false);
                  }}
                >
                  {' '}
                  + Create FAQ
                </button>
              )}
            </span>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card__body">
                  {editFaq ? (
                    navPreview ? (
                      <>
                        <div
                          style={{ marginBottom: '30px', marginLeft: '-20px' }}
                          className="container"
                        ></div>

                        <Preview form={form} tags={tags} />
                      </>
                    ) : (
                      <>
                        <div
                          style={{ marginBottom: '30px', marginLeft: '-20px' }}
                          className="container"
                        ></div>
                        <Edit
                          form={form}
                          handleChange={handleChange}
                          handleContentChange={handleContentChange}
                          createTag={createTag}
                          removeTag={removeTag}
                          tagResponseMsg={tagResponseMsg}
                          tags={tags}
                          showTagFields={showTagFields}
                          setShowTagFields={setShowTagFields}
                          removeSelectedFile={removeSelectedFile}
                          handleTagFormChange={handleTagFormChange}
                          tagForm={tagForm}
                          handleImageChange={handleImageChange}
                          fileError={fileError}
                        />
                      </>
                    )
                  ) : (
                    <Table
                      limit={limit}
                      headData={TableHead}
                      renderHead={(item, index) => renderHead(item, index)}
                      bodyData={faqList}
                      renderBody={(item, index, currPage) =>
                        renderBody(
                          item,
                          index,
                          setEditFaq,
                          setNavPreview,
                          handleClick,
                          currPage,
                          renderTooltip
                        )
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <ScaleLoader
          color="#36d7b7"
          style={{
            display: 'flex',
            height: '100vh',
            width: '100vw',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        />
      )}
    </div>
  );
};

export default FAQ;

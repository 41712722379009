import { useEffect, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Alert from 'react-bootstrap/Alert';
import '../assets/css/site.css'
import EditCarousel from '../components/site/EditCarousel';
import EditText from '../components/site/EditText';
import axiosInstance from '../utility/axios';

const Site = () => {
    const [activeTab, setActiveTab] = useState('banner');
    const [responseMsg, setResponseMsg] = useState('');
    const [lang, setLang] = useState('en');
    const [stats, setStats] = useState({})
    const [aboutMyAmbar, setAboutMyAmbar] = useState({
        en: '',
        hi: ''
    });

    const handleSaveStats = () => {
        axiosInstance
        .post(`/site/updateStats`, {
            stats
        })
        .then((response) => {
          if (response.status === 200) {
            getStats()
          } else {
            // TODO
          }
          setResponseMsg(response.data?.message);
        })
        .catch((err) => {
          console.log(err);
          setResponseMsg(err);
        });
    }

    const handleResetStats = () => {
        getStats()
    }

    const getStats = () => {
        axiosInstance
        .get(`/site/getStats`, {
          headers: {
            isadmin: true,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setStats({...response.data?.response});
          } else {
            // TODO
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    useEffect(() => {
        getStats()
    }, [])

    const RenderBanner = ({lang}) => {
        return (
            <div className="row">
                <div className="col-12">
                    <div className="card site-settings">
                        <div className='site-settings-header'>
                            <h4 className="site-settings-banner">Banner Image</h4>
                        </div>
                        <EditCarousel lang={lang} />
                    </div>
                </div>
            </div>
        )
    }
    
    const RenderAbout = ({lang}) => {
        return (
            <div className="row" style={{marginTop: '20px'}}>
                <div className="col-12">
                        <EditText lang={lang} aboutMyAmbar={aboutMyAmbar} setAboutMyAmbar={setAboutMyAmbar} />
                </div>
            </div>
        )
    }

    const RenderStats = () => {
        return (
            <div className="row stats-cnt" style={{marginTop: '20px'}}>
                {
                responseMsg && <Alert variant="info" onClose={() => setResponseMsg('')} dismissible>
                                <p>
                                    {responseMsg}
                                </p>
                                </Alert>
                }
                <div style={{display: "flex", justifyContent: "flex-end"}}>
                    <button className="banner-btn" onClick={()=> handleSaveStats()}>Save</button>
                    <button className="banner-btn" onClick={()=> handleResetStats()}>Reset</button>
                </div>
                <div className="col-12" style={{display: "flex", flexWrap: "wrap"}}>
                <div className="container">
                    <h1>States</h1>
                    <div className="count">{stats?.states}</div>
                    <div>
                        <button className="decrement" onClick={()=> setStats({...stats, states: stats.states - 1})}>-</button>
                        <button className="increment" onClick={()=> setStats({...stats, states: stats.states + 1})}>+</button>
                    </div>
                </div>
                <div className="container">
                    <h1>Districts</h1>
                    <div className="count">{stats?.districts}</div>
                    <div>
                        <button className="decrement" onClick={()=> setStats({...stats, districts: stats.districts - 1})}>-</button>
                        <button className="increment" onClick={()=> setStats({...stats, districts: stats.districts + 1})}>+</button>
                    </div>
                </div>
                <div className="container">
                    <h1>Union Territories</h1>
                    <div className="count">{stats?.ut}</div>
                    <div>
                        <button className="decrement" onClick={()=> setStats({...stats, ut: stats.ut - 1})}>-</button>
                        <button className="increment" onClick={()=> setStats({...stats, ut: stats.ut + 1})}>+</button>
                    </div>
                </div>
                
                </div>
            </div>
        )
    }

    return (
        <div id="site-container">
            <h2 className="page-header">Site Settings</h2>
            <Tabs
                // defaultActiveKey="en"
                className="mb-3"
                onSelect={(key)=>setActiveTab(key)}
                activeKey={activeTab}
                >
                <Tab eventKey="banner" title="Banner Image" >
                    <Tabs
                        // defaultActiveKey="en"
                        className="mb-3"
                        onSelect={(key)=>setLang(key)}
                        activeKey={lang}
                        >
                        <Tab eventKey="en" title="English" >
                            <RenderBanner lang={lang} activeTab={activeTab}/>
                        </Tab>
                        <Tab eventKey="hi" title="Hindi">
                            <RenderBanner lang={lang} activeTab={activeTab}/>
                        </Tab>
                    </Tabs>
                </Tab>
                <Tab eventKey="about" title="About">
                    <Tabs
                        // defaultActiveKey="en"
                        className="mb-3"
                        onSelect={(key)=>setLang(key)}
                        activeKey={lang}
                        >
                        <Tab eventKey="en" title="English" >
                            <RenderAbout lang={lang} activeTab={activeTab}/>
                        </Tab>
                        <Tab eventKey="hi" title="Hindi">
                            <RenderAbout lang={lang} activeTab={activeTab}/>
                        </Tab>
                    </Tabs>
                </Tab>
                <Tab eventKey="stats" title="Stats" >
                    <RenderStats/>
                </Tab>
            </Tabs>
        </div>
    )
}

export default Site
import React, { useState } from 'react';

const PreviewParagraph = ({ text, wordLimit }) => {
  const [showButton, setShowButton] = useState(false);

  const truncateParagraph = (text, limit) => {
    const words = text.split(' ');

    if (words.length > limit) {
      const truncatedText = words.slice(0, limit).join(' ');
      return `${truncatedText}...`;
    }

    return text;
  };

  const handleButtonClick = () => {
    setShowButton(!showButton);
  };

  const truncatedText = truncateParagraph(text, wordLimit);

  return (
    <div>
      <p dangerouslySetInnerHTML={{__html: showButton ? text : truncatedText}}></p>
      {truncatedText !== text && (
        <button className='btn btn-primary' onClick={handleButtonClick} style={{backgroundColor: "rgb(115, 113, 238)"}}>
          {showButton ? 'Read Less' : 'Read More'}
        </button>
      )}
    </div>
  );
};

export default PreviewParagraph;

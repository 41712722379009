import React from 'react';

import { Link } from 'react-router-dom';

import './sidebar.css';

import logo from '../../assets/images/amabarlogo.png';

import sidebar_items from '../../assets/JsonData/sidebar_routes.json';

import TopNav from '../topnav/TopNav';

const SidebarItem = (props) => {
  const active = props.active ? 'active' : '';

  return (
    <div className="sidebar__item">
      <div className={`sidebar__item-inner ${active}`}>
        <i className={props.icon}></i>
        <span>{props.title}</span>
      </div>
    </div>
  );
};

const Sidebar = (props) => {
  const activeItem = sidebar_items.findIndex(
    (item) => item.route === props.location.pathname
  );

  return (
    <div className="sidebar">
      <div className="header">
        <TopNav />
      </div>
      {window.innerWidth < 1000 ? (
        <>
          <input
            type="checkbox"
            className="openSidebarMenu"
            id="openSidebarMenu"
          />
          <label htmlFor="openSidebarMenu" className="sidebarIconToggle">
            <div className="spinner diagonal part-1"></div>
            <div className="spinner horizontal"></div>
            <div className="spinner diagonal part-2"></div>
          </label>

          <div id="sidebarMenu">
            <div className="sidebar__logo">
              <img src={logo} alt="company logo" />
            </div>
            {sidebar_items.map((item, index) => (
              <Link
                to={item.route}
                key={index}
                onClick={() => {
                  let checkbox = document.getElementById('openSidebarMenu');
                  checkbox.checked = false;
                }}
              >
                <SidebarItem
                  title={item.display_name}
                  icon={item.icon}
                  active={index === activeItem}
                />
              </Link>
            ))}
          </div>
        </>
      ) : (
        <>
          <div className="sidebar__logo">
            <img src={logo} alt="company logo" />
          </div>
          {sidebar_items.map((item, index) => (
            <Link to={item.route} key={index}>
              <SidebarItem
                title={item.display_name}
                icon={item.icon}
                active={index === activeItem}
              />
            </Link>
          ))}
        </>
      )}
    </div>
  );
};

export default Sidebar;

import { useState, useEffect, useRef } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';

import './style.css';

const Edit = ({
  handleChange,
  handleContentChange,
  handleImageChange,
  form,
  fileError,
  removeSelectedFile,
  handleTagChange,
  removeTag,
  createTag,
  tags,
  tagResponseMsg,
  showTagFields,
  setShowTagFields,
  setBlogImageTracker,
  blogImageTracker,
}) => {
  const [tagName, setTagName] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [tagId, setTagId] = useState(-1);
  const uploadAdapter = (loader) => {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append('file', file);
            fetch(`${process.env.REACT_APP_API}/blogs/image/uploads`, {
              method: 'post',
              body: body,
              credentials: 'include',
              // mode: "no-cors"
            })
              .then((res) => res.json())
              .then((res) => {
                setBlogImageTracker([...blogImageTracker, res.url]);
                resolve({
                  default: `${process.env.REACT_APP_API}/${res.url}`,
                });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      },
    };
  };
  const uploadPlugin = (editor) => {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  };
  const handleClick = (id) => {
    setTagId(id);
    setShowModal(true);
  };
  return (
    <>
      <div
        className={`modal ${showModal ? 'show' : ''} fade`}
        tabIndex="-1"
        style={{ display: showModal ? 'block' : 'none' }}
        id="blog_edit"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ backgroundColor: '#008b8b' }}
            >
              <h5 className="modal-title" style={{ color: 'white' }}>
                Delete
              </h5>
              <button
                className="btn-close btn-close-white"
                type="button"
                aria-label="Close"
                data-bs-dismiss="modal"
                onClick={() => setShowModal(false)}
              ></button>
            </div>
            <div className="modal-body">
              <div style={{ marginLeft: '1rem' }}>
                Are you sure want to delete this item?
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-light"
                type="button"
                id="TableModalClose"
                data-bs-dismiss="modal"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
              <button
                type="submit"
                name="next"
                onClick={() => removeTag(tagId, setShowModal)}
                data-bs-dismiss="modal"
                className="btn btn-primary action-button"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal-backdrop ${showModal ? 'show' : ''} fade`}
        style={{ display: showModal ? 'block' : 'none' }}
      ></div>
      <div className="mb-3">
        <label htmlFor="blogTitle" className="form-label">
          Title *
        </label>
        <input
          type="text"
          className="form-control"
          id="blogTitle"
          name="title"
          placeholder=""
          value={form.title}
          onChange={handleChange}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="blogAuthors" className="form-label">
          Authors
        </label>
        <input
          type="text"
          className="form-control"
          id="blogAuthors"
          name="authors"
          placeholder=""
          value={form.authors}
          onChange={handleChange}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="blogSource" className="form-label">
          Original Source
        </label>
        <input
          type="text"
          className="form-control"
          id="blogSource"
          name="source"
          placeholder="www.xyz.com"
          value={form.source}
          onChange={handleChange}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="blogDate" className="form-label">
          Date *
        </label>
        <input
          type="date"
          className="form-control"
          id="blogDate"
          name="date"
          placeholder=""
          value={form.date}
          onChange={handleChange}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="blogTag" className="form-label">
          Tags
        </label>
        <div className="tag-container">
          <div className="dropdown">
            <button
              className="btn btn-secondary dropdown-toggle"
              type="button"
              id="blogTag"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Select Tags
            </button>
            <ul className="dropdown-menu" aria-labelledby="blogTag">
              {tags.map((tag, index) => (
                <div key={index}>
                  <li className="dropdown-item" key={tag.id}>
                    <input
                      className="form-check-input me-1"
                      type="checkbox"
                      value={tag.name}
                      aria-label="..."
                      onChange={handleTagChange}
                      checked={form.tag?.includes(tag.name)}
                    />
                    {tag.name}
                    <button
                      type="button"
                      style={{ padding: '0 .5em', marginLeft: '.5em' }}
                      data-bs-toggle="modal"
                      data-bs-target="#blog_edit"
                      onClick={() => handleClick(tag.id)}
                    >
                      X
                    </button>
                  </li>
                </div>
              ))}
            </ul>
          </div>
          <div>
            <button
              className="btn btn-secondary "
              onClick={() => setShowTagFields(!showTagFields)}
            >
              {' '}
              {!showTagFields ? '+ Add' : 'Remove'} New Tag
            </button>
          </div>
        </div>
        {showTagFields && (
          <div className="input-group mb-3" style={{ margin: '1em 0' }}>
            <input
              type="text"
              className="form-control"
              id="tagName"
              name="tagName"
              placeholder="Tag Name"
              value={tagName}
              onChange={(e) => setTagName(e.target.value)}
            />
            <button
              className="btn btn-outline-secondary"
              type="button"
              id="button-addon2"
              onClick={() => {
                createTag(tagName);
                setTagName('');
              }}
            >
              Save
            </button>
          </div>
        )}
        {tagResponseMsg}
        {/* 
            <label htmlFor="blogTag" className="form-label">Tags</label>
            <input type="text" className="form-control" id="blogTag" name="tag" placeholder="category1, category2" value={form.tag} onChange={handleChange} /> */}
      </div>
      <div className="mb-3">
        <label htmlFor="blogDemoImage" className="form-label">
          Demo Image
        </label>
        <div style={{ display: 'flex' }}>
          <input
            type="file"
            className="form-control"
            id="demoImage"
            accept=".jpg,.jpeg,.png"
            multiple={false}
            name="demoImage"
            onChange={handleImageChange}
          />
          <button
            style={{ padding: '0px .5em' }}
            className="remove-button"
            type="button"
            onClick={() => removeSelectedFile('demoImage')}
          >
            X
          </button>
        </div>
        {form.demoImage ? <p>{form.demoImage.name}</p> : <p>No file chosen</p>}
        <p style={{ color: 'red' }}>{fileError?.demoImage}</p>
      </div>
      <div className="mb-3">
        <label htmlFor="blogBannerImage" className="form-label">
          Banner Image
        </label>
        <div style={{ display: 'flex' }}>
          <input
            type="file"
            className="form-control"
            id="bannerImage"
            accept=".jpg,.jpeg,.png"
            multiple={false}
            name="bannerImage"
            onChange={handleImageChange}
          />
          <button
            style={{ padding: '0px .5em' }}
            className="remove-button"
            type="button"
            onClick={() => removeSelectedFile('bannerImage')}
          >
            X
          </button>
        </div>
        {form.bannerImage ? (
          <p>{form.bannerImage.name || form.bannerImage}</p>
        ) : (
          <p>No file chosen</p>
        )}
        <p style={{ color: 'red' }}>{fileError?.bannerImage}</p>
      </div>
      <div className="mb-3">
        <label htmlFor="blogAuthorImage" className="form-label">
          Author's Image
        </label>
        <div style={{ display: 'flex' }}>
          <input
            type="file"
            className="form-control"
            id="authorImage"
            accept=".jpg,.jpeg,.png"
            multiple={false}
            name="authorImage"
            onChange={handleImageChange}
          />
          <button
            style={{ padding: '0px .5em' }}
            className="remove-button"
            type="button"
            onClick={() => removeSelectedFile('authorImage')}
          >
            X
          </button>
        </div>
        {form.authorImage ? (
          <p>{form.authorImage.name || form.authorImage}</p>
        ) : (
          <p>No file chosen</p>
        )}
        <p style={{ color: 'red' }}>{fileError?.authorImage}</p>
      </div>
      {/* <div className="mb-3">
            <label htmlFor="blogURL" className="form-label">Blog Url</label>
            <input type="text" className="form-control" id="blogURL" name='blogURL' placeholder="www.xyz.com" value={form.blogURL} onChange={handleChange}/>
        </div> */}
      {/* <div className="mb-3">
            <label htmlFor="blogShareLink" className="form-label">Sharing Link</label>
            <input type="text" className="form-control" id="blogShareLink" name="shareLink" placeholder="" value={form.shareLink} onChange={handleChange} />
        </div> */}
      {/* <div className="mb-3">
            <label htmlFor="blogShortDesp" className="form-label">Short Description *</label>
            <textarea rows={10} className="form-control" id="blogShortDesp" name='shortDesp' placeholder="description" value={form.shortDesp} onChange={handleChange} />
        </div> */}
      <div className="mb-3">
        <label htmlFor="blogContent" className="form-label">
          Blog *
        </label>
        <CKEditor
          editor={Editor}
          data={form.content}
          onReady={(editor) => {
            // You can store the "editor" and use when it is needed.
            uploadPlugin(editor);
            //    console.log( 'Editor is ready to use!', editor );
          }}
          onChange={handleContentChange}
          onBlur={(event, editor) => {
            //    console.log( 'Blur.', editor );
          }}
          onFocus={(event, editor) => {
            //    console.log( 'Focus.', editor );
          }}
        />
      </div>
    </>
  );
};

export default Edit;

import React, { useState, useEffect, useRef } from 'react';
import axiosInstance from '../utility/axios';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Table from '../components/table/Table';
import Edit from '../components/video/edit';
import Preview from '../components/video/preview';
import ScaleLoader from 'react-spinners/ScaleLoader';
import Alert from 'react-bootstrap/Alert';

let limit = 5;
const TableHead = ['', 'Title', 'URL', 'Date', 'Description', '', '', ''];
const renderHead = (item, index) => <th key={index}>{item}</th>;

const renderBody = (
  item,
  index,
  setEditVideo,
  setNavPreview,
  handleClick,
  currPage,
  renderTooltip
) => (
  <tr key={index}>
    <td>{currPage * limit + index + 1}</td>
    <td>{item.title}</td>
    <td>{item.url}</td>
    <td>{item.date}</td>
    <td>{item.description}</td>
    <td>
      <OverlayTrigger
        placement="bottom"
        delay={{ show: 250, hide: 400 }}
        overlay={(props) => renderTooltip(props, 'Preview')}
      >
        <i
          className="bx bxs-show"
          style={{ cursor: 'pointer', fontSize: '30px' }}
          onClick={() => {
            setNavPreview(true);
            setEditVideo(item.id);
          }}
        ></i>
      </OverlayTrigger>
    </td>
    <td>
      <OverlayTrigger
        placement="bottom"
        delay={{ show: 250, hide: 400 }}
        overlay={(props) => renderTooltip(props, 'Edit')}
      >
        <i
          className="bx bxs-edit-alt"
          onClick={() => {
            setNavPreview(false);
            setEditVideo(item.id);
          }}
          style={{ cursor: 'pointer', fontSize: '30px' }}
        ></i>
      </OverlayTrigger>
    </td>
    <td>
      <OverlayTrigger
        placement="bottom"
        delay={{ show: 250, hide: 400 }}
        overlay={(props) => renderTooltip(props, 'Delete')}
      >
        <i
          className="bx bxs-trash"
          data-bs-toggle="modal"
          data-bs-target="#video"
          style={{ cursor: 'pointer', fontSize: '30px' }}
          onClick={() => handleClick(item.id)}
        ></i>
      </OverlayTrigger>
    </td>
  </tr>
);

const Videos = () => {
  const [showModal, setShowModal] = useState(false);
  const [VideosList, setVideosList] = useState([]);
  const [editVideo, setEditVideo] = useState('');
  const [navPreview, setNavPreview] = useState(true);
  const [responseMsg, setResponseMsg] = useState('');
  const [itemId, setItemId] = useState(-1);
  const [state, setState] = useState({});
  const [loader, setLoader] = useState(false);
  const [form, setForm] = useState({
    id: '',
    title: '',
    thumbnail: '',
    url: '',
    embedURL: '',
    date: '',
    description: '',
  });

  function convertYouTubeUrlToEmbed(url) {
    const regex =
      /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([\w-]{11})/;
    const match = url.match(regex);
    if (match) {
      const videoId = match[1];
      const embedURL = `https://www.youtube.com/embed/${videoId}`;
      const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;

      return { embedURL, thumbnailUrl };
    } else {
      return { embedURL: '', thumbnailUrl: '' };
    }
  }

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleClick = (id) => {
    setItemId(id);
    setShowModal(true);
  };
  const renderTooltip = (props, text) => (
    <Tooltip id="button-tooltip" {...props}>
      {text}
    </Tooltip>
  );
  const handleSubmit = (e) => {
    setLoader(true);
    e.preventDefault();
    const formData = new FormData();
    const { embedURL, thumbnailUrl } = convertYouTubeUrlToEmbed(form.url);
    formData.append('title', form.title);
    formData.append('url', form.url);
    formData.append('embedURL', embedURL);
    formData.append('thumbnail', thumbnailUrl);
    formData.append('date', form.date);
    formData.append('description', form.description);

    if (form.id) {
      axiosInstance
        .patch(`/videos/update/${form.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setEditVideo('');
            updateTable();
            setLoader(false);
          } else {
            // TODO: alert
          }
          setResponseMsg(response.data?.message);
        })
        .catch((err) => {
          setResponseMsg(err);
        });
    } else {
      axiosInstance
        .post('/videos/create', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setEditVideo('');
            updateTable();
            setLoader(false);
          } else {
            // TODO: alert
          }
          setResponseMsg(response.data?.message);
        })
        .catch((err) => {
          setResponseMsg(err);
        });
    }
  };

  useEffect(() => {
    if (editVideo === '' || editVideo === -1) {
      setForm({
        ...form,
        id: '',
        title: '',
        thumbnail: '',
        url: '',
        embedURL: '',
        date: '',
        description: '',
      });
    } else {
      const Video = VideosList.filter((b) => b.id === editVideo)[0];
      setForm({
        ...form,
        id: Video?.id || '',
        title: Video?.title || '',
        thumbnail: Video?.thumbnail || '',
        url: Video?.url || '',
        embedURL: Video?.embedURL || '',
        date: Video?.date || '',
        description: Video?.description || '',
      });
    }
  }, [editVideo]);

  const updateTable = () => {
    axiosInstance
      .get('/videos/readAll', {
        headers: {
          isadmin: true,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setVideosList([...response.data?.response]);
        } else {
          // TODO
        }
      })
      .catch((err) => {
        setResponseMsg(err);
      });
  };
  useEffect(() => {
    updateTable();
    return () => {
      setState({});
    };
  }, []);

  const handleDelete = (e, id) => {
    e.stopPropagation();
    axiosInstance
      .delete(`/videos/delete/${id}`)
      .then((response) => {
        if (response.status === 200) {
          updateTable();
        } else {
          // TODO: alert
        }
        setResponseMsg(response.data?.message);
      })
      .catch((err) => {
        setResponseMsg(err);
      });
    setShowModal(false);
  };

  return (
    <div id="video-container">
      {
        responseMsg && <Alert variant="info" onClose={() => setResponseMsg('')} dismissible>
                          <p>
                            {responseMsg}
                          </p>
                        </Alert>
      }
      {!loader ? (
        <>
          <div
            className={`modal ${showModal ? 'show' : ''} fade`}
            tabIndex="-1"
            style={{ display: showModal ? 'block' : 'none' }}
            id="video"
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-lg modal-dialog-centered"
              role="document"
            >
              <div className="modal-content">
                <div
                  className="modal-header"
                  style={{ backgroundColor: '#008b8b' }}
                >
                  <h5 className="modal-title" style={{ color: 'white' }}>
                    Delete
                  </h5>
                  <button
                    className="btn-close btn-close-white"
                    type="button"
                    aria-label="Close"
                    data-bs-dismiss="modal"
                    onClick={() => setShowModal(false)}
                  ></button>
                </div>
                <div className="modal-body">
                  <div style={{ marginLeft: '1rem' }}>
                    Are you sure want to delete this item?
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    className="btn btn-light"
                    type="button"
                    id="TableModalClose"
                    data-bs-dismiss="modal"
                    onClick={() => setShowModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    name="next"
                    onClick={(e) => handleDelete(e, itemId)}
                    data-bs-dismiss="modal"
                    className="btn btn-primary action-button"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
          <h2 className="page-header">
            Videos
            <span>
              {editVideo !== '' ? (
                navPreview ? (
                  <>
                    <button onClick={handleSubmit}> Save</button>
                    <button onClick={() => setNavPreview(false)}> Edit</button>
                    <button
                      onClick={() => {
                        setEditVideo('');
                      }}
                    >
                      {' '}
                      Back
                    </button>
                  </>
                ) : (
                  <>
                    <button onClick={handleSubmit}> Save</button>
                    <button onClick={() => setNavPreview(true)}>
                      {' '}
                      Preview
                    </button>
                    <button
                      onClick={() => {
                        setEditVideo('');
                        setNavPreview(true);
                      }}
                    >
                      {' '}
                      Back
                    </button>
                  </>
                )
              ) : (
                <button
                  onClick={() => {
                    setEditVideo(-1);
                    setNavPreview(false);
                  }}
                >
                  {' '}
                  + Add Video
                </button>
              )}
            </span>
          </h2>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card__body">
                  {editVideo ? (
                    navPreview ? (
                      <Preview
                        form={form}
                        convertYouTubeUrlToEmbed={convertYouTubeUrlToEmbed}
                      />
                    ) : (
                      <Edit form={form} handleChange={handleChange} />
                    )
                  ) : (
                    <Table
                      limit={limit}
                      headData={TableHead}
                      renderHead={(item, index) => renderHead(item, index)}
                      bodyData={VideosList}
                      renderBody={(item, index, currPage) =>
                        renderBody(
                          item,
                          index,
                          setEditVideo,
                          setNavPreview,
                          handleClick,
                          currPage,
                          renderTooltip
                        )
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <ScaleLoader
          color="#36d7b7"
          style={{
            display: 'flex',
            height: '100vh',
            width: '100vw',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        />
      )}
    </div>
  );
};

export default Videos;

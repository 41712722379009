import { useState, useEffect, useRef } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';

import './style.css';

const Edit = ({
  handleChange,
  handleContentChange,
  handleImageChange,
  form,
  fileError,
  removeSelectedFile,
  removeTag,
  createTag,
  tagResponseMsg,
  showTagFields,
  setShowTagFields,
  handleTagFormChange,
  tagForm,
  tags,
}) => {
  const [tagName, setTagName] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [tagId, setTagId] = useState(-1);
  const [isValid, setIsValid] = useState(true);

  const handleClick = (id) => {
    setTagId(id);
    setShowModal(true);
  };
  return (
    <>
      <div
        className={`modal ${showModal ? 'show' : ''} fade`}
        tabIndex="-1"
        style={{ display: showModal ? 'block' : 'none' }}
        id="faq_edit"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ backgroundColor: '#008b8b' }}
            >
              <h5 className="modal-title" style={{ color: 'white' }}>
                Delete
              </h5>
              <button
                className="btn-close btn-close-white"
                type="button"
                aria-label="Close"
                data-bs-dismiss="modal"
                onClick={() => setShowModal(false)}
              ></button>
            </div>
            <div className="modal-body">
              <div style={{ marginLeft: '1rem' }}>
                Are you sure want to delete this item?
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-light"
                type="button"
                id="TableModalClose"
                data-bs-dismiss="modal"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
              <button
                type="submit"
                name="next"
                onClick={() => removeTag(tagId, setShowModal)}
                data-bs-dismiss="modal"
                className="btn btn-primary action-button"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal-backdrop ${showModal ? 'show' : ''} fade`}
        style={{ display: showModal ? 'block' : 'none' }}
      ></div>

      <div className="mb-3">
        <label htmlFor="blogTitle" className="form-label">
          Question
        </label>
        <input
          type="text"
          className="form-control"
          id="blogTitle"
          name="question_en"
          placeholder=""
          value={form.question_en}
          onChange={handleChange}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="blogContent" className="form-label">
          Answer
        </label>
        <CKEditor
          editor={Editor}
          data={form.answer_en}
          onReady={(editor) => {
            editor.ui.view.toolbar.items.get(11).buttonView.isVisible = false;
            // editor.ui.view.toolbar.items.get(16).buttonView.isVisible = false;
            editor.ui.view.toolbar.items.get(31).isVisible = false;
            editor.ui.view.toolbar.items.get(32).buttonView.isVisible = false;
            editor.ui.view.toolbar.items.get(33).buttonView.isVisible = false;
            editor.ui.view.toolbar.items.get(34).buttonView.isVisible = false;
            editor.ui.view.toolbar.items.get(35).buttonView.isVisible = false;
          }}
          onChange={(event, editor) => handleContentChange(event, editor, true)}
          onBlur={(event, editor) => {}}
          onFocus={(event, editor) => {}}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="blogTitle" className="form-label">
          Question(Hindi)
        </label>
        <input
          type="text"
          className="form-control"
          id="blogTitle"
          name="question_hi"
          placeholder=""
          value={form.question_hi}
          onChange={handleChange}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="blogContent" className="form-label">
          Answer(Hindi)
        </label>
        <CKEditor
          editor={Editor}
          data={form.answer_hi}
          onReady={(editor) => {
            editor.ui.view.toolbar.items.get(11).buttonView.isVisible = false;
            // editor.ui.view.toolbar.items.get(16).buttonView.isVisible = false;
            editor.ui.view.toolbar.items.get(31).isVisible = false;
            editor.ui.view.toolbar.items.get(32).buttonView.isVisible = false;
            editor.ui.view.toolbar.items.get(33).buttonView.isVisible = false;
            editor.ui.view.toolbar.items.get(34).buttonView.isVisible = false;
            editor.ui.view.toolbar.items.get(35).buttonView.isVisible = false;
          }}
          onChange={(event, editor) =>
            handleContentChange(event, editor, false)
          }
          onBlur={(event, editor) => {}}
          onFocus={(event, editor) => {}}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="blogTag" className="form-label">
          Q&A Category
        </label>
        <div className="tag-container">
          <div className="dropdown">
            <button
              className="btn btn-secondary dropdown-toggle"
              type="button"
              id="blogTag"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Select Category
            </button>
            <ul className="dropdown-menu" aria-labelledby="blogTag">
              {tags.map((tag, index) => (
                <div key={index}>
                  <li className="dropdown-item" key={tag.id}>
                    <input
                      className="form-check-input"
                      type="radio"
                      value={tag.id}
                      onChange={handleChange}
                      name="tag_id"
                      id="flexRadioDefault1"
                    />
                    <label
                      className="form-check-label me-1"
                      htmlFor="flexRadioDefault1"
                    >
                      {tag.name_en + '  -  ' + tag.name_hi}
                    </label>

                    <button
                      type="button"
                      style={{ padding: '0 .5em', marginLeft: '.5em' }}
                      data-bs-toggle="modal"
                      data-bs-target="#faq_edit"
                      onClick={() => handleClick(tag.id)}
                    >
                      X
                    </button>
                  </li>
                </div>
              ))}
            </ul>
          </div>

          <div>
            <button
              className="btn btn-secondary"
              onClick={() => setShowTagFields(!showTagFields)}
            >
              {' '}
              {!showTagFields ? '+ Add' : 'Remove'} New Category
            </button>
          </div>
        </div>
        {showTagFields && (
          <>
            <div className="mb-3">
              <label
                htmlFor="tagDemoImage"
                className="form-label"
                style={{ marginTop: '20px' }}
              >
                Category Image
              </label>
              *
              <div style={{ display: 'flex' }}>
                <input
                  type="file"
                  className="form-control"
                  id="tagImage"
                  accept=".jpg,.jpeg,.png"
                  multiple={false}
                  name="tagImage"
                  onChange={handleImageChange}
                />
                <button
                  style={{ padding: '0px .5em' }}
                  className="remove-button"
                  type="button"
                  onClick={() => removeSelectedFile('tagImage')}
                >
                  X
                </button>
              </div>
              {tagForm.tagImage ? (
                <p>{tagForm.tagImage.name || tagForm.tagImage}</p>
              ) : (
                <p>No file chosen</p>
              )}
              <p style={{ color: 'red' }}>{fileError?.tagImage}</p>
            </div>
            <div className="input-group mb-3" style={{ margin: '1em 0' }}>
              <input
                type="text"
                className="form-control"
                id="tagName"
                name="tagName_en"
                placeholder="Category Name"
                value={tagForm.tagName_en}
                onChange={handleTagFormChange}
              />
              <input
                type="text"
                className="form-control"
                id="tagName"
                name="tagName_hi"
                placeholder="Category Name(Hindi)"
                value={tagForm.tagName_hi}
                onChange={handleTagFormChange}
              />
              <button
                className="btn btn-outline-secondary"
                type="button"
                id="button-addon2"
                onClick={() => {
                  createTag(
                    tagForm.tagName_en,
                    tagForm.tagName_hi,
                    tagForm.tagImage
                  );
                  setTagName('');
                }}
              >
                Add
              </button>
            </div>
          </>
        )}
        {tagResponseMsg}
        {tagResponseMsg === 'Unable to create Category' && (
          <p style={{ color: 'red' }}>Please enter all fields</p>
        )}
      </div>
    </>
  );
};

export default Edit;
